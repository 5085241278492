/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { COLORS, Header, TextStyle } from '../../../../../ui-kit';

import { randomNumberBetween } from '../../../../../utils/random';

const MAX_RADIUS = 30;
const MIN_RADIUS = 10;
const MIN_SPEED = 0.5;
const MAX_SPEED = 1.25;
const MIN_OPACITY = 0.25;
const MAX_OPACITY = 0.8;
const CANVAS_HEIGHT = 600;
const NUM_DOTS = 20;

export default class FloatingDotHeader extends React.PureComponent {
  currentCanvasWidth = 0;

  pixelRatio = 1;

  animation = null;

  constructor(props) {
    super(props);

    this.setupCanvas = this.setupCanvas.bind(this);
    this.resizeCanvas = this.resizeCanvas.bind(this);
    this.createCircleObject = this.createCircleObject.bind(this);

    this.state = {
      circles: []
    };
  }

  componentDidMount() {
    this.pixelRatio =
      window.devicePixelRatio == null ? 1 : window.devicePixelRatio;

    window.addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();

    // eslint-disable-next-line no-unused-vars
    const circles = Array.from(Array(NUM_DOTS)).map(_ =>
      this.createCircleObject(Math.random() * CANVAS_HEIGHT)
    );

    this.setState(
      {
        circles
      },
      () => {
        this.animation = this.createAnimation();
        this.animation.start();
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
    this.animation.stop();
  }

  setupCanvas(canvas) {
    if (canvas == null) {
      return;
    }
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
  }

  createCircleObject(startingY) {
    const radius = randomNumberBetween(MIN_RADIUS, MAX_RADIUS);
    const speed = randomNumberBetween(MIN_SPEED, MAX_SPEED);
    const alpha = randomNumberBetween(MIN_OPACITY, MAX_OPACITY);
    const y = startingY || CANVAS_HEIGHT + radius;

    return {
      x: randomNumberBetween(0, this.currentCanvasWidth) / this.pixelRatio,
      y,
      radius,
      speed,
      alpha
    };
  }

  resizeCanvas() {
    this.currentCanvasWidth = window.innerWidth * this.pixelRatio;
    this.canvas.width = this.currentCanvasWidth;
    this.canvas.style.width = `${window.innerWidth}px`;
    this.ctx.scale(this.pixelRatio, this.pixelRatio);
  }

  createAnimation() {
    // Allows us to stop animation on component unmount
    let cancelled = false;

    const animate = () => {
      const circlesToRemove = [];
      this.ctx.clearRect(0, 0, this.currentCanvasWidth, CANVAS_HEIGHT);
      this.ctx.fillStyle =
        COLORS[this.props.color.toUpperCase().replace('-', '_')];
      this.ctx.globalCompositeOperation = 'destination-over';

      this.state.circles.forEach((circle, index) => {
        const { x, y, radius, speed, alpha } = circle;

        this.ctx.beginPath();
        this.ctx.arc(x, y, radius, 0, Math.PI * 2, false);
        this.ctx.globalAlpha = alpha;

        this.ctx.fill();

        if (y > 0 - radius) {
          circle.y = y - speed;
        } else {
          circlesToRemove.push(index);
        }
      });

      if (circlesToRemove.length > 0) {
        const newCircles = this.state.circles.slice();
        circlesToRemove.forEach(index => {
          newCircles.splice(index, 1);
          newCircles.push(this.createCircleObject());
        });

        if (!cancelled) {
          this.setState({ circles: newCircles });
        }
      }

      if (!cancelled) {
        window.requestAnimationFrame(() => {
          animate();
        });
      }
    };

    return {
      start: animate,
      stop() {
        cancelled = true;
      }
    };
  }

  render() {
    const { color, title } = this.props;

    return (
      <section className="component__floating-dot-header">
        <div className="Container">
          <canvas
            ref={this.setupCanvas}
            height={CANVAS_HEIGHT}
            className="Canvas"
          />
          <TextStyle color={color}>
            <Header>{title}</Header>
          </TextStyle>
        </div>
      </section>
    );
  }
}

/* eslint-disable react/prop-types */

import React from 'react';
import { LeadText, Stack, TextStyle } from '../../../../../ui-kit';

import './inspirational-articles.scss';

export default function InspirationalArticles({ data }) {
  return (
    <section className="component__inspirational-articles">
      <div className="Container">
        {data.map(({ title, image, url, blurb }) => (
          <div className="Article" key={title}>
            <a href={url}>
              <Stack vertical>
                <div
                  className="ArticleImage"
                  style={{ backgroundImage: `url(${image})` }}
                />
                <LeadText alignment="left">{title}</LeadText>
                <p>
                  <TextStyle color="gray-dark">{blurb.blurb}</TextStyle>
                </p>
              </Stack>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
}

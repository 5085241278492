/* eslint-disable react/prop-types */
import React from 'react';
import { Header, TextStyle } from '../../../../../ui-kit';

import './scientific-articles.scss';

export default function ScientificArticles({ color, data, source }) {
  return (
    <section className="component__scientific-articles">
      <div className="Container">
        {data.map(({ title, url }) => (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a className="Article" href={url} target="_blank" key={title}>
            <div
              className={`Background Background-${color[0].toLowerCase()}`}
            />
            <Header>
              <TextStyle color="white">{title}</TextStyle>
            </Header>
            <span className="Source">{source}</span>
          </a>
        ))}
      </div>
    </section>
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';

import FloatingDotHeader from './components/floating-dot-header';
import ScientificArticles from './components/scientific-articles';
import InspirationalArticles from './components/inspirational-articles';

import './articles.scss';

import { LayoutUI, Stack } from '../../../ui-kit';

const slugify = require('slugify');

const ArticleProgram = ({ location, data }) => {
  const {
    // metaData,
    color,
    slug,
    dayOfWeek,
    articles
  } = data.contentfulPageArticle;

  const isInspirational = !!slug.includes('inspirational');

  return (
    <Layout>
      <Navbar location={location} />
      <MobileNavbar />
      <main className="page__article">
        <FloatingDotHeader color={color[0]} title={dayOfWeek} />
        {articles.length > 0 && (
          <div className="Articles">
            <LayoutUI>
              <Stack vertical spacing="extraLoose">
                <div className="LinksContainer">
                  <Stack distribution="center">
                    <a
                      href={`/science-of-happiness/${slugify(
                        dayOfWeek[0]
                      ).toLowerCase()}/inspirational`}
                      className={`Link-${color} ${
                        isInspirational ? 'ActiveLink' : ''
                      }
                      `}
                    >
                      Inspirational
                    </a>
                    <a
                      href={`/science-of-happiness/${slugify(
                        dayOfWeek[0]
                      ).toLowerCase()}/scientific`}
                      className={`Link-${color} ${
                        !isInspirational ? 'ActiveLink' : ''
                      }
                      `}
                    >
                      Scientific
                    </a>
                  </Stack>
                </div>

                {slug.includes('scientific') && (
                  <ScientificArticles data={articles} color={color} />
                )}
                {slug.includes('inspirational') && (
                  <InspirationalArticles data={articles} />
                )}
              </Stack>
            </LayoutUI>
          </div>
        )}
      </main>
    </Layout>
  );
};

ArticleProgram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

export default ArticleProgram;

export const articleQuery = graphql`
  query articles($slug: String!) {
    contentfulPageArticle(slug: { eq: $slug }) {
      color
      slug
      dayOfWeek
      articles {
        title
        url
        blurb {
          blurb
        }
        image
        source
      }
    }
  }
`;
